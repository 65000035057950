import * as React from "react"
import { graphql, Link, PageProps, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentSection from "../components/Shared/ContentSection"
import TestimonialsSection from "../components/Shared/TestimonialsSection"
import ContentWrapper from "../components/Shared/ContentWrapper"
import HeroVideo from "../components/Heroes/Video"
import PortfolioItem from "../components/Portfolio/PortfolioItem"

type DataProps = {
  site: object
}
const PortfolioPage: React.FC<PageProps<DataProps>> = ({}) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPortfolioCategory {
        nodes {
          id
          name
          slug
          portfolio_post {
            id
          }
        }
      }
      allContentfulPortfolioPost(sort: { order: ASC, fields: projectDate }) {
        nodes {
          id
          title
          featuredImage {
            gatsbyImageData(
              width: 800
              resizingBehavior: SCALE
              quality: 12
              height: 450
            )
            file {
              details {
                image {
                  width
                  height
                }
              }
            }
          }
          slug
          createdAt
          categories {
            id
            slug
            name
          }
        }
      }
      folioImage: file(relativePath: { eq: "folio/1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  `)
  const Nav = () => (
    <div className="fixed-top-panel filter-panel fl-wrap">
      <div className="fixed-filter-panel_title color-bg">
        Works Filter <i className="fal fa-long-arrow-right" />
      </div>
      <div className="gallery-filters inline-dark-filters">
        <a
          href="#"
          className="gallery-filter gallery-filter-active"
          data-filter="*"
        >
          All projects
        </a>
        {Array.isArray(data.allContentfulPortfolioCategory.nodes) &&
          data.allContentfulPortfolioCategory.nodes.map(category => (
            <a
              key={category.id}
              href="#"
              className="gallery-filter"
              data-filter={`.${category.slug}`}
            >
              {category.name}
            </a>
          ))}
      </div>
      <div className="folio-counter">
        <div className="num-album" />
        <div className="all-album" />
      </div>
    </div>
  )
  return (
    <Layout title={data.title || "Portfolio"} showScrollProgress={false}>
      <Seo title={data.title || "Portfolio"} />

      <div className="content dark-content portf-wrap">
        <Nav />
        <div className="gallery-items min-pad four-column vis-det fl-wrap  ">
          {Array.isArray(data.allContentfulPortfolioPost.nodes) &&
            data.allContentfulPortfolioPost.nodes.map(item => (
              <PortfolioItem key={item.id} post={item} />
            ))}
        </div>
      </div>
      <div className="limit-box fl-wrap" />
    </Layout>
  )
}

export default PortfolioPage
