import * as React from "react"
import { Link } from "gatsby"
import { getSrc, getSrcSet } from "gatsby-plugin-image"

export default ({ post }) => {
  const featuredImageSrc = getSrc(post.featuredImage)
  const featuredImageSrcSet = getSrcSet(post.featuredImage)

  const categoryTags = post.categories.map(category => category.slug).join(" ")
  const largeItem =
    post.featuredImage.gatsbyImageData.aspectRatio > 1.78
      ? "gallery-item-second"
      : ""
  return (
    <div className={`gallery-item  ${categoryTags}  ${largeItem}`}>
      <div className="grid-item-holder hov_zoom">
        <img
          src={featuredImageSrc}
          srcSet={featuredImageSrcSet}
          alt={post.title}
        />
        <a href={featuredImageSrc} className="box-media-zoom   image-popup">
          <i className="fal fa-search" />
        </a>
        <div className="grid-det">
          <div className="grid-det_category">
            {Array.isArray(post.categories) &&
              post.categories.map(category => (
                <a key={category.id} href="#">
                  {category.name}
                </a>
              ))}
          </div>
          <div className="grid-det-item">
            <Link to={`/portfolio/${post.slug}`} className="ajax grid-det_link">
              {post.title}
              <i className="fal fa-long-arrow-right" />
            </Link>
          </div>
        </div>
      </div>
      <div className="pr-bg" />
    </div>
  )
}
